export default defineNuxtPlugin((nuxtApp) => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      console.log("checking for cookie");
      return null;
    };

    const fetchLocationData = async () => {
      try {
        const response = await fetch('https://edge.cookiefirst.com/location');
        const data = await response.json();
        setLocationCookie({country: data.countryCode, region: data.region});
        return data;
      } catch (error) {
        console.error('Error fetching location data:', error);
        return null;
      }
    };
  
    const setLocationCookie = (value) => {
      if (value) {
        const expirationDate = new Date(Date.now() + 604800000); // Expires in 7 days
        const countryValue = encodeURIComponent(value.country);
        document.cookie = `country=${countryValue}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict; Secure`;
        const regionValue = encodeURIComponent(value.region);
        document.cookie = `region=${regionValue}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict; Secure`;
      }
    };
  
    
    nuxtApp.hook('app:mounted', async () => {
      const locationCookie = getCookie('country');
      if (!locationCookie) {
        await fetchLocationData();
      }
    });
  });
  