export const options = {
  "images": true,
  "videos": true,
  "audios": true,
  "iframes": true,
  "native": false,
  "directiveOnly": false,
  "defaultImage": false,
  "loadingClass": "is-lazy-loading",
  "loadedClass": "is-lazy-loaded",
  "appendClass": "lazy-load",
  "observerConfig": {}
}