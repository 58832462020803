<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number;
    statusMessage: string;
    message: string;
  };
}>();

const { t } = useI18n();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const errorMessageMap: { [key: number]: string } = {
  404: t("errorPages.404"),
  408: t("errorPages.408"),
  500: t("errorPages.500"),
  502: t("errorPages.502"),
  503: t("errorPages.503"),
};

const errorMessage =
  props.error.statusMessage ||
  errorMessageMap[props.error.statusCode as keyof typeof errorMessageMap];
</script>

<script lang="ts">
export default {
  name: "ErrorPage",
};
</script>

<template>
  <div
    class="px-5 py-3 md:py-20 md:px-32 lg:px-24 lg:py-24 items-center flex justify-center flex-col-reverse lg:flex-row"
  >
    <div class="flex flex-col items-center justify-center my-8">
      <div class="max-w-md text-center">
        <h1 class="mb-8 font-extrabold text-9xl">
          <span class="sr-only">{{ $t("error") }}</span
          >{{ props.error.statusCode }}
        </h1>
        <p class="text-xl md:text-3xl font-semibold mt-4 mb-8">
          {{ errorMessage }}
        </p>
        <p>
          {{ props.error.message }}
        </p>
        <NuxtLink
          :to="formatLink(`/`)"
          class="w-full lg:w-auto justify-center py-3 px-8 border shadow-sm text-sm font-medium rounded-md text-white bg-brand-light hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand-primary"
        >
          {{ $t("goBackHome") }}
        </NuxtLink>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <img v-lazy-load 
        class="w-full h-full max-w-md max-h-md"
        data-src="~/assets/error-background.png"
        alt="Error"
      />
    </div>
  </div>
</template>
